/* Media query for mobile devices */
@media (max-width: 768px) {
  body {
    background-color: #282c34;
    color: white;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
  }

  .fullContainer {
    display: flex;
    flex-direction: column;
    height: 85vh; /* Full viewport height */
  }

  .asciiContainer {
    padding-top: 0.5vh;
    font-size: 0.5vmin; /* Font size scales with viewport width */
  }

  .artDiv {
    height: 60%;
    text-align: center;
    margin-bottom: 2vh;
  }

  .textDiv {
    font-size: min(2.75vw, 12px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 40%;
    margin: 12px;
    white-space: pre-wrap;
  }

  ul, li {
    list-style: none;
    padding: 3px;
    line-height: 2.5em;
  }

  button {
    font-family: Consolas, monospace;
    font-size: min(2.75vw, 12px);
    padding: 3px;
    background-color: #282c34; /* Set the default button color */
    color: white; /* Set the default text color */
  }
}





/* Media query for desktop devices */
@media (min-width: 769px) {
  body {
    background-color: #282c34;
    color: white;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
  }

  .fullContainer {
    display: flex;
    flex-direction: column;
    height: 97vh; /* Full viewport height */
    padding-top: 20px;
  }

  .asciiContainer {
    font-size: 0.45vmin; /* Font size scales with viewport width */
  }

  .artDiv {
    height: 60%;
    text-align: center;
  }

  .textDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    vertical-align: top;
    height: 40%;
    margin: 0 20%;
    white-space: pre-wrap;
  }

  ul, li {
    list-style: none;
    padding: 0px;
    line-height: 2.5em;
  }

  button {
    font-family: Consolas, monospace;
    font-size: min(1.5vw, 16px);
    padding: 3px;
    background-color: #282c34; /* Set the default button color */
    color: white; /* Set the default text color */
    transition: background-color 0.3s ease; /* Smooth transition effect */
  }
  
  button:hover{
    background-color: white; /* Change background color on hover */
    color: #282c34; /* Change text color on hover */
  }
}